.toolCallResponseViewer {
  height: 100%;
  overflow-y: hidden;
  background-color: var(--color-bg-elevation-2);
}

.toolCallResponseContainer {
  display: flex;
  flex-direction: column;
  gap: var(--inner-spacing-2);
  width: 100%;
}

.toolCallResponseViewerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
}

.toolCallResponseViewer :global(.CodeMirror) {
  font-size: unset !important;
  line-height: unset !important;
  font-family:
    SFMono-Regular,
    Menlo,
    Consolas,
    Liberation Mono,
    monospace !important;
  background: var(--color-bg-elevation-2) !important;
}

.toolCallResponseViewer :global(.CodeMirror-gutters) {
  background-color: var(--color-bg-elevation-2) !important;
}

.toolCallResponseViewer :global(.CodeMirror-linenumber) {
  color: var(--color-fg-neutral-subtle) !important;
  font-weight: 400 !important;
}

.toolCallResponseViewer :global(.CodeMirror-guttermarker-subtle::after) {
  color: var(--color-fg-neutral-subtle) !important;
}

.toolCallResponseViewer :global(.cm-m-javascript) {
  color: var(--color-fg-neutral-subtle) !important;
}

.toolCallResponseViewer :global(.cm-string) {
  color: var(--color-fg) !important;
}
.toolCallResponseViewer :global(.cm-number) {
  color: var(--color-fg) !important;
}
.toolCallResponseViewer :global(.cm-atom) {
  color: var(--color-fg) !important;
}
.toolCallResponseViewer :global(.cm-variable) {
  color: var(--color-fg) !important;
}

.toolCallResponseViewer :global(.cm-property) {
  color: var(--color-fg-neutral-subtle) !important;
}
